import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";

function JournalGuard({ children }) {
  const {isMedtigoJournalAdmin} = useSelector((state) => state.account.user);
  const {enqueueSnackbar} = useSnackbar();

  if (!isMedtigoJournalAdmin) {
    enqueueSnackbar('Unauthorised Access!', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        }
    });
    return <Redirect to="/login" />;
  }

  return children;
}

JournalGuard.propTypes = {
  children: PropTypes.any
};

export default JournalGuard;
