/* eslint-disable import/prefer-default-export */

export const THEMES = {

  LIGHT: 'LIGHT',

  ONE_DARK: 'ONE_DARK',

  UNICORN: 'UNICORN'

};

export const APP_URL = 'https://connect.medtigo.com/api/v1';
// export const APP_URL = 'http://localhost:8080/api/v1';

// export const APP_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/v1' : (process.env.NODE_ENV === 'qa'? 'http://qa.medtigo.com/api/v1' : 'https://connect.medtigo.com/api/v1');

export const SERVER_URL = 'https://connect.medtigo.com/';

// export const APP_URL = 'http://localhost:8080/api/v1';

// export const SERVER_URL = 'http://localhost:8080/';
