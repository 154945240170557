import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";

function AccessGuard({ children, roles }) {
  const { user } = useSelector(state => state.account);
  const {enqueueSnackbar} = useSnackbar();
  
  if (!roles.includes(user.role)) {
    enqueueSnackbar('Unauthorised Access!', {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      }
  });
    return <Redirect to="/login" />;
  }

  return children;
}

AccessGuard.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AccessGuard;