import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }
        return Promise.reject(
          error.response.data !== undefined ? error.response.data : error
        );
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  updateUserProfile = user =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      for (let key in user) {
        formData.append(key, user[key]);
      }
      axios
        .put('/user/update', formData)
        .then(response => {
          if (response.data) {
            const res = response.data;
            if (
              res?.updatedUser.profilePicture &&
              res?.updatedUser.profilePicture.includes('files')
            ) {
              res.updatedUser.profilePicture =
                res.updatedUser.profilePicture + '?ms=' + new Date().getTime();
            }
            resolve(res);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          reject(error.error);
        });
    });

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/userLogin', { email, password })
        .then(response => {
          if (response.data) {
            if (response.data.token) {
              this.setSession(response.data.token);
              resolve(response.data.data)
            } else if (response.data.message === 'TwoFactorAuth') {
              resolve(response.data.message)
            }
          } else {
            reject(response.message);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

    refreshToken = (duration="2h") =>
      new Promise((resolve, reject) => {
        axios
          .post('/auth/refresh', {duration})
          .then(response => {
            if (response.data) {
              this.setSession(response.data.token);
              resolve(response.data.data);
            } else {
              reject(response.message);
            }
          })
          .catch(error => {
            reject(error.message);
          });
      });

  loginWithEmailAndOtp = (email, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/login/verify-otp', { email, otp })
        .then(response => {
          if (response.data) {
            this.setSession(response.data.token);
            resolve(response.data.data)
          } else {
            reject(response.message);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  enableTwoFactorAuth = async data => {
    try {
      let response = await axios.post('/auth/enable2fa', data);
      let ress = {
        data: response.data.data,
      }
      return ress;
    } catch (error) {
      let ress = {
        data: error.data,
        message:error.message
      }
      return ress;
    }
  };

  disableTwoFactorAuth = () =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/disable2fa')
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.message);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  forgotPassword = email =>
    new Promise((resolve, reject) => {
      axios
        .post('/user/forgot-password', { email })
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          reject(error.error);
        });
    });

  changePassword = values =>
    new Promise((resolve, reject) => {
      axios
        .put('/user/update/user/password', values)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          reject(error.error);
        });
    });

  registerUser = (values, endpoint = '') =>
    new Promise((resolve, reject) => {
      delete values.policy;
      axios
        .post(`/auth/signup${endpoint ? '/' + endpoint : ''}`, values)
        .then(response => {
          if (response.data) {
            this.setSession(response.data.token);
            resolve(response.data.data);
          } else {
            reject(response.message);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/user')
        .then(response => {
          if (response.data.user) {
            if (
              window.location.pathname !== '/login' &&
              !response.data.user.wpUserID
            ) {
              this.logout();
              window.location.href = '/login';
              return;
            }
            resolve(response.data.user);
          } else {
            if (response.data.code === 401) {
              this.logout();
              window.location.href = '/login';
            }
            reject(response.data.error);
          }
        })
        .catch(error => {
          if (error.code === 401) {
            this.logout();
            window.location.href = '/login';
          }
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = async accessToken => {
    if (!accessToken) {
      return false;
    }
    try {
      const decoded = await jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (e) {
      console.log(e);
    }
  };

  validateResetToken = token =>
    new Promise((resolve, reject) => {
      axios
        .post('/user/validate-token', { resetToken: token })
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          reject(error.error);
        });
    });

    addUserToRoot = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/add-user-root', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

     syncOnWordpress = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/wordpress-user-sync', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

   sendWelcomeEmail = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/welcome-email', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  sendLicensingOnBoardingEmail = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/licensing-onboarding-email', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });



  resetPassword = res =>
    new Promise((resolve, reject) => {
      axios
        .post('/user/reset-user-password', res)
        .then(response => {
          if (response.data) {
            this.setSession(response.data.token);
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          reject(error.error);
        });
    });

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
