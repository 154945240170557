import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import usersService from '../services/userService';

function TrackingGuard({ children }) {
  const account = useSelector(state => state.account);

  if (
    !usersService.securedUsers().find(user => user.email === account.user.email)
  ) {
    return <Redirect to="/app/account" />;
  }

  return children;
}

TrackingGuard.propTypes = {
  children: PropTypes.any
};

export default TrackingGuard;
