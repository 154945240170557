import axios from 'src/utils/axios';

class UsersService {
  addUser = values =>
    new Promise((resolve, reject) => {
      axios
        .post('/admin/users/add', values)
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getUserProgresses = email =>
    new Promise((resolve, reject) => {
      axios
        .get(`/lms/course/progress?email=${email}`)
        .then(response => {
          // debugger;
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          // debugger;
          reject(error);
        });
    });

  assignRoles = values =>
    new Promise((resolve, reject) => {
      axios
        .post('/admin/users/assign-roles', values)
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  deleteUser = values =>
    new Promise((resolve, reject) => {
      axios
        .post('/admin/users/delete', values)
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  unAssignUser = id =>
    new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/unassign-role/${id}`)
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  requestUserAccess = user_id =>
    new Promise((resolve, reject) => {
      axios
        .post('/admin/users/request-access', { user_id })
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  editUser = (user_id, values) =>
    new Promise((resolve, reject) => {
      axios
        .put(`/admin/users/${user_id}/edit`, values)
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  updateCourseBulk = (lesson_id, values, userId) =>
    new Promise((resolve, reject) => {
      axios
        .put(`/lms/courses/update-progress/bulk/${lesson_id}`, {
          lessons: values,
          userID: userId
        })
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  securedUsers = () => {
    const restricted = [
      { name: 'rkulkarni', email: 'rkulkarni@medtigo.com' },
      { name: 'rswami', email:'rswami@medtigo.com'},
    ];

    return restricted;
  };

  getUsers = (hospital_id, department_id) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `/admin/users/?hospital_id=${
            hospital_id ? hospital_id : ''
          }&department_id=${department_id ? department_id : ''}`
        )
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  adminUsers = inputVal =>
    new Promise((resolve, reject) => {
      axios
        .get(
          'admin/users?limit=100&q=:inputVal'.replace(/:inputVal/gi, inputVal)
        )
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    
    getProviderDetails = (id)=>
    new Promise((resolve, reject) => {
      axios
        .get(
          `user/get/providerDetails?user_id=${id}`
        )
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

const usersService = new UsersService();

export default usersService;
